<script setup>
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import NormFeeItem from "../components/NormFeeItem.vue";

const toast = useToast();

const serviceFees = reactive({
    roomFee: "",
    foodFee: "",
    kmFee: "",
    specialist: "",
    tutor_management_system: "",
    tutor_13485: "",
    tutor_aerospace: "",
    veto_check_cert: "",
    veto_check_usa: "",
});

const norms = ref([]);

async function updateServiceFees() {
    await axios
        .put("v2/service-fees", {
            room_fee: serviceFees.roomFee,
            food_fee: serviceFees.foodFee,
            km_fee: serviceFees.kmFee,
            specialist: serviceFees.specialist,
            tutor_management_system: serviceFees.tutor_management_system,
            tutor_13485: serviceFees.tutor_13485,
            tutor_aerospace: serviceFees.tutor_aerospace,
            veto_check_cert: serviceFees.veto_check_cert,
            veto_check_usa: serviceFees.veto_check_usa,
        })
        .then((response) => {
            console.log("response", response.data);
            toast.success("Honorarios actualizados correctamente", {
                timeout: 3000,
            });
        })
        .catch((error) => {
            console.log("error", error);
        });
}

async function getNorms() {
    await axios.get("norms").then((response) => {
        response.data.data.forEach((norm) => {
            switch (norm.norm) {
                case "ISO 9001":
                    norm.order = 1;
                    break;
                case "ISO 14001":
                    norm.order = 2;
                    break;
                case "ISO 45001":
                    norm.order = 3;
                    break;
                case "ISO 50001":
                    norm.order = 4;
                    break;
                case "ISO 27001":
                    norm.order = 5;
                    break;
                case "ISO 13485":
                    norm.order = 6;
                    break;
                case "HACCP":
                    norm.order = 7;
                    break;
                case "ISO 22000":
                    norm.order = 8;
                    break;
                case "FSSC 22000":
                    norm.order = 9;
                    break;
                default:
                    norm.order = 100;
            }
        });

        norms.value = response.data.data.sort((a, b) => a.order - b.order);
    });
}

onMounted(() => {
    axios.get("v2/service-fees").then((response) => {
        serviceFees.roomFee = response.data.room_fee;
        serviceFees.foodFee = response.data.food_fee;
        serviceFees.kmFee = response.data.km_fee;
        serviceFees.specialist = response.data.specialist;
        serviceFees.tutor_management_system =
            response.data.tutor_management_system;
        serviceFees.tutor_13485 = response.data.tutor_13485;
        serviceFees.tutor_aerospace = response.data.tutor_aerospace;
        serviceFees.veto_check_cert = response.data.veto_check_cert;
        serviceFees.veto_check_usa = response.data.veto_check_usa;
    });

    getNorms();
});
</script>

<template>
    <div class="mt-8 grid grid-cols-1 w-full gap-4 text-slate-600">
        <div class="bg-slate-50 rounded-xl py-8 px-4 shadow-slate-500/25">
            <div class="flex flex-row items-center justify-between mb-4">
                <h2 class="text-xl font-bold">Honorarios</h2>
                <form @submit.prevent class="flex gap-4">
                    <div class="flex gap-4 flex-wrap justify-end">
                        <div class="flex flex-col">
                            <label for="roomFee">Alojamiento</label>
                            <input
                                id="roomFee"
                                v-model="serviceFees.roomFee"
                                type="text"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="foodFee">Comida</label>
                            <input
                                id="foodFee"
                                type="text"
                                v-model="serviceFees.foodFee"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Km recorrido</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.kmFee"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee"
                                >Tutor Curso Sistema de Gestión</label
                            >
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.tutor_management_system"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Tutor Curso 13485</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.tutor_13485"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Tutor Curso Aeroespacial</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.tutor_aerospace"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Especialista</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.specialist"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Veto Check TN CERT</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.veto_check_cert"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="kmFee">Veto Check TN USA</label>
                            <input
                                id="kmFee"
                                type="text"
                                v-model="serviceFees.veto_check_usa"
                                class="bg-slate-100 border border-slate-300 placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none"
                            />
                        </div>
                    </div>
                    <div class="flex items-center relative">
                        <button
                            @click="updateServiceFees"
                            class="bg-slate-200 border border-slate-300 text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 py-4 relative top-2 hover:bg-slate-300 outline-none focus:outline-none"
                        >
                            Actualizar
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div
            v-if="norms"
            class="bg-slate-50 rounded-xl py-8 px-4 shadow-slate-500/25"
        >
            <span class="px-4 h-8 text-slate-500 block"
                >Presione la tecla enter en cada campo para actualizar su
                valor.</span
            >
            <div v-for="norm in norms" :key="norm.id">
                <NormFeeItem :norm />
            </div>
        </div>
    </div>
</template>
