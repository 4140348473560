<script>
import { mapActions } from "vuex";
import Modal from "../../views/spa/_elements/Modal.vue";
import eacs from "../../views/spa/mixins/eacs";

export default {
    name: "SettingsNorm",
    components: {
        Modal,
    },
    mixins: [eacs],
    props: {
        norm: {
            required: true,
        },
    },
    data() {
        return {
            editNorm: false,
            editDescription: false,
            eacs: this.$store.state.eacs.eacs,
            risks: this.$store.state.risks.risks,
            eacRiskForm: {
                norm: this.norm.id,
                eac: null,
                risk: "",
            },
        };
    },
    computed: {
        normEacs() {
            return this.sortNormEacsByName(this.norm.eacs_norms);
        },
        orderedEacs() {
            return this.$store.state.eacs.eacs.sort((a, b) =>
                a.eac.localeCompare(b.eac, "en", {
                    numeric: true,
                    sensitivity: "base",
                })
            );
        },
    },
    methods: {
        ...mapActions({
            updateNorm: "norms/updateNorm",
            deleteNorm: "norms/deleteNorm",
            storeEacNorm: "norms/storeEacNorm",
            deleteEacNorm: "norms/deleteEacNorm",
        }),
        clearEacNormForm() {
            this.eacRiskForm.eac = "";
            this.eacRiskForm.risk = "";
        },
        storeEacNormFn() {
            this.storeEacNorm(this.eacRiskForm);
            this.clearEacNormForm();
        },
        updateNormFees(norm) {
            this.updateNorm(norm);
            this.$refs.normFees.closeModal();
        },
    },
};
</script>

<template>
    <div
        class="grid grid-cols-5 items-center gap-4 mb-4 py-4 px-2 hover:bg-white"
    >
        <div class="col-span-1">
            <span v-if="editNorm">
                <input
                    type="text"
                    v-model="norm.norm"
                    placeholder="Norma"
                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    @keyup.esc="editNorm = false"
                    @keyup.enter="
                        updateNorm(norm);
                        editNorm = false;
                    "
                />
            </span>
            <span class="h-8" v-else>
                {{ norm.norm }}
                <font-awesome-icon
                    icon="fa-duotone fa-pen-line"
                    class="cursor-pointer"
                    @click="editNorm = true"
                ></font-awesome-icon>
            </span>
        </div>
        <div class="col-span-1" ref="descriptionField">
            <span v-if="editDescription">
                <input
                    type="text"
                    v-model="norm.description"
                    placeholder="Descripción"
                    class="w-full py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                    @keyup.esc="editDescription = false"
                    @keyup.enter="
                        updateNorm(norm);
                        editDescription = false;
                    "
                />
            </span>
            <span class="h-8" v-else>
                <span v-if="norm.description">{{ norm.description }} </span>
                <span class="text-slate-400 italic" v-else
                    >Agregar una descripción</span
                >
                <font-awesome-icon
                    icon="fa-duotone fa-pen-line"
                    class="cursor-pointer text-slate-600"
                    @click="editDescription = true"
                ></font-awesome-icon>
            </span>
        </div>
        <div class="col-span-2">
            <span
                class="flex flex-row flex-wrap"
                v-if="normEacs && normEacs.length > 0"
            >
                <span class="mx-1 my-2" v-for="eac in normEacs" :key="eac.id">
                    <span
                        class="rounded-full px-2 py-1 bg-blue-500 text-white w-8"
                    >
                        <span v-if="eac.eac && eac.eac.eac">{{
                            eac.eac.eac
                        }}</span>
                        <span v-if="eac.risk">{{ eac.risk.risk }}</span>
                        <span
                            title="Eliminar"
                            v-title
                            class="cursor-pointer"
                            @click="deleteEacNorm(eac)"
                            >&nbsp;&nbsp;x&nbsp;&nbsp;</span
                        >
                    </span>
                </span>
            </span>
            <span v-else>
                <span class="text-slate-400 italic">
                    Esta norma no tiene alcances
                </span>
            </span>
        </div>
        <div class="col-span-1">
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-2 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Agregar Alcances"
                @click="$refs.addEacRisk.openModal()"
            >
                <font-awesome-icon
                    icon="fa-duotone fa-diagram-project"
                    class="text-lg text-slate-600"
                />
            </button>
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-2 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Editar Honorarios"
                @click="$refs.normFees.openModal()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    color="#4a4a4a"
                    fill="none"
                    class="inline-block"
                >
                    <path
                        d="M3.3457 16.1976L16.1747 3.36866M18.6316 11.0556L16.4321 13.2551M14.5549 15.1099L13.5762 16.0886"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M3.17467 16.1411C1.60844 14.5749 1.60844 12.0355 3.17467 10.4693L10.4693 3.17467C12.0355 1.60844 14.5749 1.60844 16.1411 3.17467L20.8253 7.85891C22.3916 9.42514 22.3916 11.9645 20.8253 13.5307L13.5307 20.8253C11.9645 22.3916 9.42514 22.3916 7.85891 20.8253L3.17467 16.1411Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                    />
                    <path
                        d="M4 22H20"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                </svg>
            </button>
            <button
                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-2 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                v-title
                title="Eliminar"
                @click="deleteNorm(norm)"
            >
                <font-awesome-icon
                    icon="fa-duotone fa-trash-can"
                    class="text-lg text-slate-600"
                />
            </button>
        </div>

        <modal ref="addEacRisk">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    Editar alcances norma {{ norm.norm }}
                </h2>
            </template>

            <template v-slot:body>
                <div>
                    <div class="text-base">
                        <span v-if="normEacs.length > 0">
                            <span
                                v-for="(eac, index) in normEacs"
                                :key="eac.id"
                            >
                                <span v-if="eac.eac">{{ eac.eac.eac }}</span>
                                <span v-if="eac.risk">{{ eac.risk.risk }}</span
                                ><span v-if="index + 1 < normEacs.length"
                                    >,
                                </span>
                            </span>
                        </span>
                        <span v-else>
                            <span class="text-slate-400 italic">
                                Esta norma no tiene alcances
                            </span>
                        </span>
                    </div>
                    <form @submit.prevent id="addEacRisk" name="addEacRisk">
                        <div
                            class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs items-end"
                        >
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Alcance</label
                                >
                                <select
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="eac"
                                    id="eac"
                                    v-model="eacRiskForm.eac"
                                >
                                    <option value="" disabled>
                                        Seleccionar Alcance
                                    </option>
                                    <option
                                        v-for="eac in orderedEacs"
                                        :key="eac.id"
                                        :value="eac.id"
                                    >
                                        {{ eac.eac }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Riesgo</label
                                >
                                <select
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="risk"
                                    id="risk"
                                    v-model="eacRiskForm.risk"
                                >
                                    <option value="" disabled>
                                        Seleccionar Riesgo
                                    </option>
                                    <option
                                        v-for="risk in risks"
                                        :key="risk.id"
                                        :value="risk.id"
                                    >
                                        {{ risk.risk }}
                                    </option>
                                </select>
                            </div>
                            <button
                                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-4 py-2 h-12 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                                v-title
                                title="Guardar"
                                @click="storeEacNormFn()"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-floppy-disk"
                                    class="text-lg text-slate-600"
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-between">
                    <!-- <div>
                        
                        <button class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center" @click="updateServiceTempBtn(serviceData)">
                            <font-awesome-icon icon="fa-duotone fa-floppy-disk" class="text-xl text-white md:mr-2" />
                            <span class="hidden md:inline-block">Guardar</span>
                        </button>
                    </div>
                    <div class="flex flex-row justify-center">
                        <button class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2 flex flex-row items-center" @click="$refs.modificarServicio.closeModal()">
                            <font-awesome-icon icon="fa-duotone fa-xmark" class="text-xl text-slate-600 md:mr-2" />
                            <span class="hidden md:inline-block">Cancelar</span>
                        </button>
                        <button class="bg-rose-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center" @click="deleteServiceTempBtn(serviceData)">
                            <font-awesome-icon icon="fa-duotone fa-trash-can" class="text-xl text-white md:mr-2" />
                            <span class="hidden md:inline-block">Eliminar</span>
                        </button>
                    </div> -->
                </div>
            </template>
        </modal>

        <modal ref="normFees">
            <template v-slot:header>
                <h2 class="text-lg font-bold text-slate-600">
                    Honorarios {{ norm.norm }}
                </h2>
            </template>

            <template v-slot:body>
                <div>
                    <form @submit.prevent id="editNormFee" name="editNormFee">
                        <div
                            class="grid grid-cols-1 xl:grid-cols-3 gap-4 text-xs items-end"
                        >
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Auditor Senior</label
                                >
                                <input
                                    type="text"
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="senior"
                                    id="senior"
                                    v-model="norm.senior_fee"
                                />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="leaderFee"
                                    >Auditor Líder</label
                                >
                                <input
                                    type="text"
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    id="leaderFee"
                                    v-model="norm.leader_fee"
                                />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-slate-400" for="status"
                                    >Auditor Acompañante</label
                                >
                                <input
                                    type="text"
                                    class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                                    name="senior"
                                    id="senior"
                                    v-model="norm.companion_fee"
                                />
                            </div>
                            <button
                                class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-4 py-2 h-12 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2"
                                v-title
                                title="Guardar"
                                @click="updateNormFees(norm)"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-floppy-disk"
                                    class="text-lg text-slate-600"
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-between">
                    <!-- <div>
                        
                        <button class="bg-blue-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center" @click="updateServiceTempBtn(serviceData)">
                            <font-awesome-icon icon="fa-duotone fa-floppy-disk" class="text-xl text-white md:mr-2" />
                            <span class="hidden md:inline-block">Guardar</span>
                        </button>
                    </div>
                    <div class="flex flex-row justify-center">
                        <button class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-6 py-3 uppercase rounded-xl tracking-wide text-xs text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2 flex flex-row items-center" @click="$refs.modificarServicio.closeModal()">
                            <font-awesome-icon icon="fa-duotone fa-xmark" class="text-xl text-slate-600 md:mr-2" />
                            <span class="hidden md:inline-block">Cancelar</span>
                        </button>
                        <button class="bg-rose-600 shadow shadow-slate-500/25 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out flex flex-row items-center" @click="deleteServiceTempBtn(serviceData)">
                            <font-awesome-icon icon="fa-duotone fa-trash-can" class="text-xl text-white md:mr-2" />
                            <span class="hidden md:inline-block">Eliminar</span>
                        </button>
                    </div> -->
                </div>
            </template>
        </modal>
    </div>
</template>
