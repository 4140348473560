<script setup>
import axios from "axios";
import { ref } from "vue";
import { useUserStore } from "../stores/user";
const user = useUserStore();
import { useToast } from "vue-toastification";
const toast = useToast();

const props = defineProps({
    norm: {
        type: Object,
        required: true,
    },
});

const updatingNorm = ref(false);

async function updateNorm() {
    updatingNorm.value = true;
    await axios
        .put(
            `norms/${props.norm.id}`,
            {
                norm: props.norm.norm,
                description: props.norm.description,
                senior_fee: props.norm.senior_fee,
                leader_fee: props.norm.leader_fee,
                companion_fee: props.norm.companion_fee,
            },
            {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            }
        )
        .then((response) => {
            toast.success(
                `Norma ${props.norm.norm} actualizada correctamente`,
                {
                    timeout: 3000,
                }
            );
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            updatingNorm.value = false;
        });
}
</script>

<template>
    <div class="flex items-center hover:bg-slate-100 px-4 py-2 rounded-xl">
        <div class="flex flex-col w-52 relative">
            <span class="text-base font-bold">{{ norm.norm }}</span>
            <span v-if="updatingNorm" class="absolute top-6 text-slate-400"
                >Actualizando...</span
            >
        </div>
        <div class="flex gap-4 mb-4">
            <div class="flex flex-col">
                <label for="seniorFee">Senior</label>
                <input
                    id="seniorFee"
                    type="text"
                    v-model="norm.senior_fee"
                    placeholder="Valor para senior"
                    class="bg-slate-100 border border-slate-300 placeholder:text-left placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none text-right h-10"
                />
            </div>

            <div class="flex flex-col">
                <label for="leaderFee">Líder</label>
                <input
                    id="leaderFee"
                    type="text"
                    v-model="norm.leader_fee"
                    placeholder="Valor para líder"
                    class="bg-slate-100 border border-slate-300 placeholder:text-left placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 w-52 mb-0 outline-none focus:outline-none text-right h-10"
                />
            </div>
            <div class="flex flex-col">
                <label for="companionFee">Acompañante</label>
                <input
                    id="companionFee"
                    type="text"
                    v-model="norm.companion_fee"
                    placeholder="Valor para acompañante"
                    class="bg-slate-100 border border-slate-300 placeholder:text-left placeholder:text-slate-400 placeholder:italic text-xs rounded-xl self-center focus:text-slate-700 mb-0 outline-none focus:outline-none text-right h-10 w-52"
                />
            </div>
            <div>
                <button
                    @click="updateNorm"
                    class="bg-slate-200 border border-slate-300 text-xs rounded-xl grid place-content-center focus:text-slate-700 w-fit mb-0 py-2 px-6 relative top-4 hover:bg-slate-300 outline-none focus:outline-none"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        color="#4a4a4a"
                        fill="none"
                    >
                        <path
                            d="M8 22V19C8 17.1144 8 16.1716 8.58579 15.5858C9.17157 15 10.1144 15 12 15C13.8856 15 14.8284 15 15.4142 15.5858C16 16.1716 16 17.1144 16 19V22"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M10 7H14"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M3 11.8584C3 7.28199 3 4.99376 4.38674 3.54394C4.43797 3.49038 4.49038 3.43797 4.54394 3.38674C5.99376 2 8.28199 2 12.8584 2C13.943 2 14.4655 2.00376 14.9628 2.18936C15.4417 2.3681 15.8429 2.70239 16.6452 3.37099L18.8411 5.20092C19.9027 6.08561 20.4335 6.52795 20.7168 7.13266C21 7.73737 21 8.42833 21 9.81025V13C21 16.7497 21 18.6246 20.0451 19.9389C19.7367 20.3634 19.3634 20.7367 18.9389 21.0451C17.6246 22 15.7497 22 12 22C8.25027 22 6.3754 22 5.06107 21.0451C4.6366 20.7367 4.26331 20.3634 3.95491 19.9389C3 18.6246 3 16.7497 3 13V11.8584Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
