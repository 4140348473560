<script setup>
import AppLogo from "./AppLogo.vue";
import AppSidebarItem from "./AppSidebarItem.vue";
import ButtonZohoSync from "./ButtonZohoSync.vue";
import { mapActions, mapMutations } from "vuex";
import { useUserStore } from "../stores/user";
import { ref, computed } from "vue";

const userStore = useUserStore();

const menuOperations = ref([]);
const menuAccountancy = ref([]);
const menuAkademie = ref([]);
const menuSettings = ref([]);
const menuGeneral = ref([]);
const menuAuditors = ref([]);

const isOperator = computed(() => {
    return userStore.roles.filter((role) => role.role == "operador").length > 0;
});

const isAuditor = computed(() => {
    return userStore.roles.filter((role) => role.role == "auditor").length > 0;
});

menuGeneral.value = [
    {
        route: "home",
        title: "Inicio",
        icon: "house",
    },
    {
        route: "documents",
        title: "Documentos",
        icon: "folder-open",
    },
];

menuOperations.value = [
    {
        route: "dashboardoperations",
        title: "Dashboard",
        icon: "chart-mixed",
    },
    {
        route: "certifications",
        title: "Certificaciones",
        icon: "rectangle-list",
    },
    {
        route: "auditorsList",
        title: "Auditores",
        icon: "user-tie",
    },
];

menuAkademie.value = [
    {
        route: "akademie.courses",
        title: "Certificados",
        icon: "file-certificate",
    },
];

menuAccountancy.value = [
    {
        route: "invoices",
        title: "Facturación",
        icon: "file-invoice-dollar",
    },
];

menuSettings.value = [
    {
        route: "userslist",
        title: "Usuarios",
        icon: "user-group",
    },
    {
        route: "settingsnorms",
        title: "Ajustes",
        icon: "gear",
    },
];

menuAuditors.value = [
    {
        route: "auditorfees",
        title: "Honorarios",
        icon: "user-tie",
    },
];
</script>

<template>
    <aside
        class="flex flex-col justify-start top-0 z-20 bg-tuvblack w-full h-full"
        id="header"
    >
        <AppLogo class="w-24 mx-auto mb-4" />
        <div class="flex flex-col justify-between h-full">
            <nav>
                <div>
                    <!-- <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">General</p> -->
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuGeneral"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>
                <div v-if="isOperator">
                    <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">
                        Operaciones
                    </p>
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuOperations"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>

                <div v-if="isOperator">
                    <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">
                        Administración
                    </p>
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuAccountancy"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>

                <div v-if="isOperator">
                    <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">
                        Akademie
                    </p>
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuAkademie"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>
                <div v-if="isAuditor">
                    <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">
                        Información
                    </p>
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuAuditors"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>

                <div v-if="isOperator">
                    <p class="text-tuvdarkgray mb-1 mt-4 text-sm pl-3">
                        Configuración
                    </p>
                    <ul
                        class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-16 lg:mt-0 lg:w-full"
                    >
                        <AppSidebarItem
                            v-for="item in menuSettings"
                            :key="item.route"
                            :route="item.route"
                            :icon="item.icon"
                            :title="item.title"
                        />
                    </ul>
                </div>

                <!-- <ul
                    class="p-0 flex flex-col lg:opacity-100 lg:transition-none mt-8 lg:w-full"
                    v-if="getRole == 'auditor'"
                >
                    <li class="navigation-item" @click="menuState()">
                        <router-link
                            class="navigation-item__link"
                            :to="{ name: 'AuditorInicio' }"
                        >
                            <i
                                class="fad fa-home-lg navigation-item__icon mr-2"
                            ></i>
                            Inicio
                        </router-link>
                    </li>
                    <li class="navigation-item" @click="menuState()">
                        <router-link
                            class="navigation-item__link"
                            :to="{ name: 'AuditorCalendario' }"
                        >
                            <i
                                class="fad fa-calendar-alt navigation-item__icon mr-2"
                            ></i>
                            Mi Calendario
                        </router-link>
                    </li>
                    <li class="navigation-item" @click="menuState()">
                        <router-link
                            class="navigation-item__link"
                            :to="{ name: 'DocumentsContainer' }"
                        >
                            <i
                                class="fad fa-copy navigation-item__icon mr-2"
                            ></i>
                            Archivos
                        </router-link>
                    </li>
                    <li class="navigation-item" @click="menuState()">
                        <router-link
                            class="navigation-item__link"
                            :to="{ name: 'AuditorMiPerfil' }"
                        >
                            <i
                                class="fad fa-user navigation-item__icon mr-2"
                            ></i>
                            Mi Perfil
                        </router-link>
                    </li>
                    <li class="navigation-item" @click="onLogout">
                        <a class="navigation-item__link" href="#"
                            ><i
                                class="fad fa-sign-out-alt navigation-item__icon mr-2"
                            ></i>
                            Salir
                        </a>
                    </li>
                </ul> -->
            </nav>
            <ButtonZohoSync class="mb-4" v-if="isOperator" />
        </div>

        <!-- <div class="w-1/2 lg:hidden">
            <div
                class="flex flex-row justify-end items-end navigation__button relative z-30 lg:hidden"
                v-if="isLoggedIn"
            >
                <div
                    class="navigation__icon w-6"
                    @click="menuState()"
                    v-bind:class="[isActive ? 'menuOpen' : 'menuClose']"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div> -->

        <!-- </div> -->
    </aside>
</template>

<style lang="scss" scoped>
.navigation-item {
    &__link {
        .navigation-item__icon {
        }
    }
    .router-link-active {
        @apply bg-slate-100 text-blue-700 z-10;
    }
}

.navigation__icon {
    width: 30px;
    height: 35px;
    position: relative;
    right: 0;

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.navigation__icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #333;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.navigation__icon span:nth-child(1) {
    top: 0px;
}

.navigation__icon span:nth-child(2),
.navigation__icon span:nth-child(3) {
    top: 9px;
}

.navigation__icon span:nth-child(4) {
    top: 18px;
}

.navigation__icon.menuOpen span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.navigation__icon.menuOpen span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.navigation__icon.menuOpen span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.navigation__icon.menuOpen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.navigation__menu.menuOpen {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.05);
    left: 0;
    width: 100vw;
    @media screen and (min-width: 992px) {
        box-shadow: none;
        width: 190px;
        min-width: 190px;
        max-width: 190px;
    }
    ul {
        opacity: 1;
    }
}
</style>
