<template>
    <div class="mb-16 mx-8">
        <header class="flex flex-row justify-between w-full">
            <div class="flex flex-col justify-start items-baseline my-2 w-1/5">
                <h1 class="text-xl text-tuvdarkblue">Honorarios</h1>
                <p class="text-tuvdarkblue ml-6 md:ml-0">
                    Honorarios para auditores
                </p>
            </div>
            <div class="w-full my-2 relative md:col-span-5 xl:col-span-6"></div>
            <div class="w-ful my-2 md:col-span-4 xl:col-span-3 md:ml-2"></div>
        </header>

        <div class="my-4 py-2">
            <p>
                A continuación se detallan los valores según la política de
                honorarios vigente
            </p>
            <div class="flex gap-4 items-top py-4">
                <a
                    class="py-0"
                    href="https://tuv-nord.com.ar/public-files/PO-RH-0201-Politica_Honorarios_Gastos-Rev28.pdf"
                    target="_blank"
                    >Descargar Política de Honorarios Vigente</a
                ><span>|</span
                ><a
                    class="py-0"
                    href="https://tuv-nord.com.ar/public-files/F-AD-0108-Detalle-Honorarios-Gastos-Rev.25.xlsx"
                    target="_blank"
                    >Descargar Planilla de honorarios</a
                >
            </div>
        </div>

        <div
            class="flex flex-col lg:flex-row lg:flex-wrap gap-4 text-slate-600 text-sm rounded-xl"
            v-if="serviceFees"
        >
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.room_fee"
            >
                <h4 class="text-base text-slate-500 h-12">Alojamiento</h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.room_fee }}
                </p>
                <p class="text-xs text-slate-500">
                    Hospedaje por noche por persona. IVA incluido
                </p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.food_fee"
            >
                <h4 class="text-base text-slate-500 h-12">Comida</h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.food_fee }}
                </p>
                <p class="text-xs text-slate-500">Por comida por persona.</p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.km_fee"
            >
                <h4 class="text-base text-slate-500 h-12">Auto particular</h4>
                <p class="text-lg text-slate-800">${{ serviceFees.km_fee }}</p>
                <p class="text-xs text-slate-500">
                    Por km recorrido con auto propio.
                </p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.tutor_management_system"
            >
                <h4 class="text-base text-slate-500 h-12">
                    Tutor Sistema de Gestión
                </h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.tutor_management_system }}
                </p>
                <p class="text-xs text-slate-500">
                    Tutor Curso Sistema de Gestión
                </p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.tutor_13485"
            >
                <h4 class="text-base text-slate-500 h-12">Tutor Curso 13485</h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.tutor_13485 }}
                </p>
                <p class="text-xs text-slate-500">Tutor Curso 13485</p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.tutor_aerospace"
            >
                <h4 class="text-base text-slate-500 h-12">
                    Tutor Curso Aeroespacial
                </h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.tutor_aerospace }}
                </p>
                <p class="text-xs text-slate-500">Tutor Curso Aeroespacial</p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.specialist"
            >
                <h4 class="text-base text-slate-500 h-12">Especialista</h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.specialist }}
                </p>
                <p class="text-xs text-slate-500">Especialista</p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.veto_check_cert"
            >
                <h4 class="text-base text-slate-500 h-12">
                    Veto Check TN CERT
                </h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.veto_check_cert }}
                </p>
                <p class="text-xs text-slate-500">
                    El valor del Veto Check equivale a la realización de 5 Vetos
                    aprobados en SAP.
                </p>
            </div>
            <div
                class="flex flex-col gap-2 items-start bg-white p-4 rounded-xl shadow-slate-500/25 w-full lg:max-w-44"
                v-if="serviceFees.veto_check_usa"
            >
                <h4 class="text-base text-slate-500 h-12">Veto Check TN USA</h4>
                <p class="text-lg text-slate-800">
                    ${{ serviceFees.veto_check_usa }}
                </p>
                <p class="text-xs text-slate-500">
                    El valor del Veto Check equivale a la realización de 4 Vetos
                    aprobados en Workflow
                </p>
            </div>
        </div>

        <header class="flex flex-row justify-between w-full mt-8">
            <div class="flex flex-col justify-start items-baseline my-2 w-1/5">
                <h1 class="text-xl text-tuvdarkblue">Normas</h1>
                <p class="text-tuvdarkblue ml-6 md:ml-0">
                    Honorarios para normas
                </p>
            </div>
            <div class="w-full my-2 relative md:col-span-5 xl:col-span-6"></div>
            <div class="w-ful my-2 md:col-span-4 xl:col-span-3 md:ml-2"></div>
        </header>

        <div class="flex justify-start gap-4">
            <div v-for="norm in user.norms" :key="norm.id">
                <div
                    class="text-left bg-white p-4 rounded-xl gap-2 text-slate-500 w-44"
                >
                    <span class="text-slate-700 text-base">{{
                        norm.norm
                    }}</span>

                    <div>
                        <span v-if="norm.status == 1">Auditor Senior</span>
                        <span v-if="norm.status == 2">Auditor Líder</span>
                        <span v-if="norm.status == 3">Auditor Acompañante</span>
                    </div>
                    <div>
                        <span class="text-slate-800" v-if="norm.status == 1"
                            >$ {{ norm.senior_fee }}</span
                        >
                        <span class="text-slate-800" v-if="norm.status == 2"
                            >$ {{ norm.leader_fee }}</span
                        >
                        <span class="text-slate-800" v-if="norm.status == 3"
                            >$ {{ norm.companion_fee }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from "axios";
import { useUserStore } from "../stores/user";
import { onMounted, ref } from "vue";

const user = useUserStore();

const serviceFees = ref(null);

onMounted(() => {
    axios.get("v2/service-fees").then((response) => {
        console.log("response");
        console.log(response.data);
        serviceFees.value = response.data;
    });
});
</script>
