import "../css/app.css";
import * as Sentry from "@sentry/vue";

import { createApp } from "vue";
import { createPinia } from "pinia";

// import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import axios from "axios";
import "@themesberg/flowbite";
import vTitle from "vuejs-title";
import { store } from "./store/store";
import router from "./router";
import App from "../views/spa/App.vue";
import JsonExcel from "vue-json-excel3";
import VueClick from "vue-click";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from "moment/moment";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCalendarCircleUser as fadCalendarCircleUser,
    faCalendarCircleExclamation as fadCalendarCircleExclamation,
    faCalendarCirclePlus as fadCalendarCirclePlus,
    faFileSlash,
    faCheck,
    faXmark,
    faFloppyDisk,
    faPenToSquare,
    faPen,
    faCircleXmark,
    faRoute,
    faTimeline,
    faFolders,
    faCircleExclamation,
    faCalendarLines,
    faFilterSlash,
    faTrashCan,
    faKey,
    faUsersSlash,
    faTrashCanUndo,
    faPaperPlane,
    faUserXmark,
    faUserSlash,
    faUserCheck,
    faChartLine,
    faFilePlus,
    faCalendarClock,
    faCommentLines,
    faUserTie,
    faBriefcase,
    faBell,
    faSpinnerThird,
    faCalendarExclamation,
    faCalendarPlus,
    faRightToBracket,
    faLock,
    faUser,
    faHouseChimney,
    faCircleInfo,
    faMagnifyingGlass,
    faUserPlus,
    faUserPen,
    faKeySkeleton,
    faPersonCircleMinus,
    faPersonCircleCheck,
    faPersonCircleXmark,
    faPersonChalkboard,
    faPersonCirclePlus,
    faArrowLeft,
    faFileCirclePlus,
    faFilterList,
    faCircleCheck,
    faSquare,
    faSquareCheck,
    faCloudArrowDown,
    faGear,
    faCirclePlus,
    faPlus,
    faPenLine,
    faCloudArrowUp,
    faList,
    faLockKeyhole,
    faChartNetwork,
    faCircleArrowLeft,
    faFileInvoiceDollar,
    faDiagramProject,
    faArrowDownAZ,
    faArrowUpAZ,
    faSave,
    faEdit,
    faTimes,
    faCircleNotch,
    faArrowsRotate,
    faFolder,
    faFileImage,
    faFilePdf,
    faFileWord,
    faFile,
    faFileExcel,
    faFilePowerpoint,
    faChevronRight,
    faFileCircleXmark,
    faGift,
    faFilm,
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faHouse as falHouse,
    faChartMixed as falChartMixed,
    faFileCertificate as falFileCertificate,
    faFileInvoiceDollar as falFileInvoiceDollar,
    faRectangleList as falRectangleList,
    faUserGroup as falUserGroup,
    faFolderOpen as falFolderOpen,
    faGear as falGear,
    faBriefcase as falBriefcase,
    faArrowRightFromBracket as falArroyRightFromBracket,
    faArrowsRotate as falArrowsRotate,
    faXmarkLarge as falXmarkLarge,
    faEllipsisVertical as falEllipsisVertical,
    faUserTie as falUserTie,
    faCloudArrowDown as falCloudArrowDown,
    faPaperPlaneTop as falPaperPlaneTop,
} from "@fortawesome/pro-light-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

library.add(
    faFileSlash,
    faFileCirclePlus,
    faFileCircleXmark,
    faGift,
    falHouse,
    falChartMixed,
    falFileCertificate,
    falPaperPlaneTop,
    faPaperPlane,
    falFileInvoiceDollar,
    falRectangleList,
    falUserGroup,
    falUserTie,
    falFolderOpen,
    falGear,
    falBriefcase,
    falArroyRightFromBracket,
    falArrowsRotate,
    falXmarkLarge,
    falCloudArrowDown,
    fadCalendarCircleUser,
    fadCalendarCircleExclamation,
    fadCalendarCirclePlus,
    faCheck,
    faXmark,
    faFloppyDisk,
    faPenToSquare,
    faPen,
    faCircleXmark,
    faRoute,
    faTimeline,
    faFilm,
    faFolders,
    faCircleExclamation,
    faCalendarLines,
    faFilterSlash,
    faTrashCan,
    faKey,
    faUsersSlash,
    faTrashCanUndo,
    faUserXmark,
    faUserSlash,
    faUserCheck,
    faChartLine,
    faFilePlus,
    faCalendarClock,
    faCommentLines,
    faUserTie,
    faBriefcase,
    faBell,
    faSpinnerThird,
    faCalendarExclamation,
    faCalendarPlus,
    faRightToBracket,
    faLock,
    faUser,
    faHouseChimney,
    faCircleInfo,
    faMagnifyingGlass,
    faUserPlus,
    faUserPen,
    faKeySkeleton,
    faPersonCircleMinus,
    faPersonCircleCheck,
    faPersonCircleXmark,
    faPersonChalkboard,
    faPersonCirclePlus,
    faArrowLeft,
    faFileCirclePlus,
    faFilterList,
    faCircleCheck,
    faSquare,
    faSquareCheck,
    faCloudArrowDown,
    faYoutube,
    faGear,
    faCirclePlus,
    faPlus,
    faPenLine,
    faCloudArrowUp,
    faList,
    faLockKeyhole,
    faChartNetwork,
    faCircleArrowLeft,
    faFileInvoiceDollar,
    faDiagramProject,
    faArrowDownAZ,
    faArrowUpAZ,
    faSave,
    faEdit,
    faTimes,
    faCircleNotch,
    faArrowsRotate,
    faFolder,
    faFileImage,
    faFilePdf,
    faFileWord,
    faFile,
    faFileExcel,
    faFilePowerpoint,
    faChevronRight,
    falEllipsisVertical
);

const pinia = createPinia();

axios.defaults.baseURL = import.meta.env.VITE_CONDOR_API_URLL;

const axiosInstance = axios.create({
    headers: { Accept: "application/json", "X-TuvNord-Condor": "bla" },
});

const vuetify = createVuetify({
    components,
    directives,
});

const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("downloadExcel", JsonExcel)
    .use(router)
    .use(store)
    .use(pinia)
    .use(VueClick)
    .use(vuetify)
    .use(Toast, {
        position: POSITION.TOP_RIGHT,
        icon: false,
    })
    .use(moment)
    .use(vTitle, {
        fontSize: "10px",
        padding: "4px 8px",
        bgColor: "#00003C",
        transitionDelay: 100,
        transitionDuration: 100,
        round: "8px",
    });

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        /https:\/\/tuv\-nord\-condor\.dev/,
        /https:\/\/tuv\-nord\.com\.ar/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.config.globalProperties.$axios = { ...axiosInstance };

app.mount("#app");
